$(document).ready(function () {
    $('.dropdown-menu').on('click', function(e) {
        e.stopPropagation();
    });
});

function resize() {

}

var timo;
window.onresize = function () {
    clearTimeout(timo);
    timo = setTimeout(resize, 100);
};

$('.navbar-toggler').click(function () {
    $(this).toggleClass('active');
});

$('.carousel').carousel().on('slide.bs.carousel', function (e) {
    var nextH = $(e.relatedTarget).height();
    $(this).find('.active.carousel-item').parent().animate({
        height: nextH
    }, 500);
});


